@import './src/styles/mixins';

.homeContent {
  font-weight: 300;
  line-height: 1.5;

  background-color: var(--gray-600);
  padding-top: 1rem;
  padding-bottom: 1px;

  @include media-down(480px) {
    padding-top: 90px;
  }
  @include media-up(480px) {
    padding-top: 55px;
  }
}

.homeContent__container {
  @include useContainer;
  margin-bottom: 2rem;
}

.homeContent__title {
  @include useVisuallyHidden;
}

.homeContent__subTitle {
  margin: 0 0 20px;

  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;

  @include media-down(640px) {
    font-size: 30px;
  }
  @include media-between(640px, 1200px) {
    font-size: 38px;
  }
  @include media-up(1200px) {
    font-size: 42px;
  }
}

.homeContent__text {
  margin: 0;

  font-size: 20px;
  text-align: center;
  color: #d4d4d4;
}

.homeContent__submit {
  position: fixed;
  bottom: 30px;

  display: inline-block;
  margin: 0;
  padding: 0 45px;

  font-weight: 400;
  font-family: inherit;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;

  background-color: #1f5bff;
  border: none;
  border-radius: 40px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  visibility: hidden;
  cursor: pointer;
  opacity: 0;

  transition-duration: 0.2s;
  transition-property: background-color, box-shadow, opacity;

  user-select: none;

  @include media-down(960px) {
    left: 50%;

    font-size: 22px;
    line-height: 50px;

    transform: translateX(-50%);
  }
  @include media-up(960px) {
    right: 30px;

    font-size: 28px;
    line-height: 60px;
  }

  &:not(:disabled):hover {
    background-color: #2353d9;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.homeContent__submit_visible {
  visibility: visible;
  opacity: 1;
}
