@import '../../../styles/mixins';

.conferenceCard {
    display: flex;
    flex-direction: column;
    padding: 20px;

    font-size: 14px;
    color: #000000;

    background-color: #fafafa;
    border-radius: 16px;
}

.conferenceCard__logo {
    position: relative;

    margin-bottom: 18px;
    padding-bottom: 50%;
}

.conferenceCard__image {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 80%;
    height: 80%;

    transform: translate(-50%, -50%);

    object-fit: contain;
    object-position: center;
}

.conferenceCard__dates {
    margin: 0;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    color: #ff3b3b;
    letter-spacing: 2.5px;
}

.conferenceCard__title {
    min-height: 56px;
    margin: 0;
    margin-bottom: 8px;

    font-weight: 500;
    font-size: 24px;
}

.conferenceCard__description {
    min-height: 63px;
    margin: 0;
}

.conferenceCard__status {
    margin: 0;
    padding-top: 5px;

    font-style: italic;
}

.conferenceCard__links {
    display: grid;
    justify-content: start;
    margin-top: auto;
    padding-top: 25px;

    gap: 6px;
    grid-auto-flow: column;
    @include media-between(480px, 640px) {
        justify-content: center;

        grid-auto-flow: row;
    }
}

.conferenceCard__link {
    display: inline-block;
    margin: 0;
    padding: 8px 12px;

    font-weight: 500;
    font-size: 13px;
    font-family: inherit;
    vertical-align: middle;
    text-align: center;
    color: #ffffff;
    text-decoration: none;

    background-color: #1f5bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    transition-duration: 0.2s;
    transition-property: color;

    user-select: none;

    &:not(:disabled):hover {
        color: #a5adc2;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
}

.conferenceCard__link_main {
}

.conferenceCard__link_alt {
    color: #5c5c5c;

    background-color: #e6e6e6;
}
