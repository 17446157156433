@import './src/styles/mixins';

.conferences {
}

.conferences__container {
    @include useContainer;
}

.conferences__text {
    margin: 0 0 90px;

    font-size: 20px;
    text-align: center;
    color: #d4d4d4;
}

.conferences__list {
    display: grid;
    margin: 0;
    padding: 0;

    list-style: none;

    grid-template-columns: repeat(auto-fill, minmax(var(--card-width), 1fr));

    @include media-down(480px) {
        margin-bottom: 90px;
    }
    @include media-up(480px) {
        margin-bottom: 135px;
    }
    @include media-down(640px) {
        --card-width: 220px;
        gap: 20px;
    }
    @include media-up(640px) {
        --card-width: 280px;
        gap: 40px;
    }
}

.conferences__item {
}

.conferences__card {
    height: 100%;
}
